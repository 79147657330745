// console.log('hello world');
require('./index.css');
// let axios = require('axios');
import axios from 'axios';
import $ from 'jquery';
let str = require('./a');
let sleep = function(ts){
    return new Promise((resolve, reject)=>{
        let sto = setTimeout(()=>{
            clearTimeout( sto );
            resolve('');
        }, ts);
    });
};
console.log(str);
!async function(){
    console.log('你好');
    await sleep(1000);
    console.log('好');
    let ret = await axios.get('http://api-dev.spreadwin.cn/ajaxTest?a=b&c=d');
    ret = ret.data;
    console.log('retfff', ret);
    console.log($('body').html());
    console.log(window.$, 'aaa', window.jQuery);
    console.log('ENV', ENV);
}();